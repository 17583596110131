@import "main-bx";

.intro {
    overflow-x: hidden;
    position: relative;
    height: 696px;
    margin-top: -81px;
    padding-top: 203px;
    background-image: url('../img/content/hero-image.jpg');    
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @include m-phone {  
        position: relative;
        height: auto;
        padding-top: 86px;
        background-image: none;
        background: linear-gradient(180deg, #FFFFFF 0%, #E2EEFF 85.14%);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            border-width: 0;
            border-style: solid;
            border-left-width: 100vw;
            border-bottom-width: 15vw;
            border-color: transparent;
            border-bottom-color: #FFFFFF;
        
        }

    }

    &__text-block {
        max-width: 553px;

        @include m-phone { 
            max-width: none;
        }
    }

    &__title {
        margin-bottom: 24px;
        font-size: 48px;
        font-weight: 600;
        line-height: 59px;
        color: $color_black;

        @include m-phone {  
            margin-bottom: 16px;
            font-size: 36px;
            line-height: 38px;            
        }
    }

    &__text {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: rgba($color_black, 0.5);

        @include m-phone {  
            font-size: 14px;
            line-height: 19px;
        }
    }

    &__btn {
        width: 221px;

        @include m-phone { 
            width: 100%;
        }
    }

    &__mobile-bg {
        display: none;

        @include m-phone {  
            display: block;
            max-width: 375px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 466px;
            background-image: url('../img/content/hero-image-mob.png');         
        }
    }
}