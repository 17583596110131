@import "main-bx";

.header {
    position: relative;
    padding-top: 19px;
    padding-bottom: 19px;
    background-color: transparent;
    z-index: 10;

    @include m-phone() {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &.open-menu {
        @include m-phone() {
            background-color: $color_white;

            .header__mobile-menu {
                position: absolute;
                top: 49px;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                padding-left: 16px;
                padding-right: 16px;
                background-color: $color_white;
                
                border-radius: 0px 0px 24px 24px;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 30px;
                    width: 100%;

                    border-radius: 0px 0px 24px 24px;
                    box-shadow: 0px 4px 20px rgba(114, 46, 209, 0.3);
                    z-index: -1;
                }
            }

            .header__nav {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-bottom: 9px;
            }

            .header__link {
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0;
                padding-top: 16px;
                padding-bottom: 16px;
                font-size: 14px;
                line-height: 17px;
                border-bottom: 1px solid #EAEAEA;

                &:last-child {
                    border-bottom: none;
                }
            }

            .header__icon-arrow {
                display: block;
                margin-left: 8px;
                margin-bottom: 2px;
                color: $color_black;
            }

            .header__icon-close {
                display: block;
            }

            .header__icon-burger {
                display: none;
            }

            .header__profile-btn {
                display: none;
            }

            .header__logout-btn {
                display: none;
            }

            .header__login-block-mobile.authorized {
                .header__profile-btn {
                    display: block;
                }

                .header__logout-btn {
                    display: block;
                }

                .header__login-btn {
                    display: none;
                }
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
    }

    &__logo-link {
        margin-right: auto;
        line-height: 0;
        transition: 0.35s all;

        &:hover {
            opacity: 0.7;
        }

        img {
            @include m-phone() {
                width: 94px;
                height: 28px;
            }
        }
    }

    &__mobile-menu {
        @include m-phone() {
            display: none;
        }
    }

    &__nav {
        display: flex;
        align-items: center;        
    }

    &__link {
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: $color_black;
        transition: 0.35s all;  

        &:hover {
            color: $color_purple;

            .header__icon-arrow {
                stroke: $color_purple;   
                color: $color_purple !important;             
            }
        }
    }

    &__btn-call {
        margin-left: 20px;

        @include m-phone() {
            padding: 8px 16px;
            font-size: 12px;
            line-height: 15px;
        }
    }

    &__btn {
        display: none;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-left: 16px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        transition: 0.35s all;

        &:hover {
            opacity: 0.7;
        }

        @include m-phone() {
            display: flex;
        }

        svg {
            flex-shrink: 0;
            pointer-events: none;
        }
    }

    &__icon-close {
        display: none;
    }

    &__icon-arrow {
        display: none;
    }
}