@import "main-bx";

.request {
    padding-top: 75px;
    padding-bottom: 125px;

    @include m-tablet () {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    &__container {
        display: flex;

        @include m-phone () {
            flex-direction: column;
            align-items: center;
        }
    }

    &__text-block {
        width: 40%;
        padding-right: 85px;

        @include m-tablet () {
            width: 50%;
            padding-right: 20px;
        }

        @include m-phone () {
            width: 100%;
            padding-right: 0;
        }
    }

    &__img-block {
        width: 60%;

        img {
            width: 100%;
            height: auto;
        }

        @include m-tablet () {
            width: 50%;
        }

        @include m-phone () {
            order: -1;
            width: 344px;
        }
    }

    &__title {
        margin-bottom: 57px;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;

        @include m-phone () {
            margin-bottom: 32px;
            font-size: 30px;           
        }
    } 

    &__input-block {
        margin-bottom: 16px;
    }

    &__checkbox-block {
        margin-top: 6px;
        margin-bottom: 58px;

        @include m-phone () {
            margin-bottom: 32px;
        }
    }

    &__button {
        width: 100%;
    }
}