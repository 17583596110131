@import "main-bx";

.input {
    width: 100%;
    padding: 16px;
    font-family: $font_base;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #000000;    
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background-color: transparent;
    transition: 0.35s all;

    @include m-tablet {
    }

    @include m-phone {
    }

    &::placeholder {
        color: #8989A2;
    }    

    &:hover,
    &:focus {
        border-color: $color_purple;
    }
}