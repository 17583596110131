@import "main-bx";

.footer {
    padding-top: 58px;
    padding-bottom: 64px;
    background: #FAFCFF;

    @include m-phone { 
        padding-top: 61px;
        padding-bottom: 60px;
    }
    
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__copyright {
        margin-bottom: 48px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #000000;

        @include m-phone { 
            margin-bottom: 32px;
        }
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
    }
}