@import "main-bx";

.button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-family: $font_base;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    text-align: center;
    color: $color_purple;
    background-color: $color_white;
    transition: 0.35s all;
    border-radius: 23px;
    border: 1px solid $color_purple;
    cursor: pointer;

    &:hover {
        color: $color_white;
        background-color: $color_purple;
    }

    &_purple {
        background-color: $color_purple;
        color: $color_white;

        &:hover {
            color: $color_purple;
            background-color: $color_white;
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}