@import "main-bx";

.checkbox {
    &__input {
        display: none;

        &:checked {
            + label {
                position: relative;

                &::before {
                    flex-shrink: 0;
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background-image: url('../img/content/check.svg');
                    background-size: 18px 18px;
                    border: none;
                }
            }
        }
    }

    &__label {
        display: flex;
        align-items: center;
        font-family: $font_base;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: #722ED1;
        cursor: pointer;

        span {
            margin-top: 3px;
        }

        // a {
        //     color: $color_blue;
        //     transition: 0.35s all;

        //     &:hover {
        //         color: $color_blue_hover;
        //         text-decoration: underline;
        //     }
        // }

        &::before {
            box-sizing: border-box;
            flex-shrink: 0;
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            margin-right: 11px;
            background-color: transparent;
            border-radius: 2px;
            border: 2px solid #722ED1;
        }
    }
}