@font-face {
    font-family: 'montserrat';
    src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
            url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
            url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
            url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
            url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}