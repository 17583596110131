@import "main-bx";

.support {
    position: relative;
    padding-top: 10vw;
    padding-bottom: 10vw;
    background: linear-gradient(193.84deg, #F5F8FE 24.74%, #E3EEFF 84.31%);

    @include m-phone {  
        padding-top: 16vw;
        padding-bottom: 16vw;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-width: 0;
        border-style: solid;
        border-right-width: 100vw;
        border-top-width: 10vw;
        border-color: transparent;
        border-top-color: #FFFFFF;    
        z-index: 5;

        @include m-phone {  
            border-top-width: 15vw;
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-width: 0;
        border-style: solid;
        border-left-width: 100vw;
        border-bottom-width: 10vw;
        border-color: transparent;
        border-bottom-color: #FFFFFF; 
        z-index: 5;    

        @include m-phone {
            border-bottom-width: 15vw;        
        }
    }

    &__container {
        display: flex;

        @include m-phone { 
            flex-direction: column;
            align-items: center;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 90px;

        @include m-tablet { 
            padding-left: 30px; 
        }

        @include m-phone { 
            align-items: center;
            padding-left: 0; 
        }
    }

    &__title {
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #722ED1;
        background-color: #F7F1FF;
        border-radius: 20px;

        @include m-phone {  
            margin-bottom: 24px;
        }

        svg {
            margin-right: 12px;
            margin-top: -2px;
        }
    }

    &__list {
        list-style-type: disc;
        padding-left: 20px;
    }

    &__item {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #8989A2;

        @include m-phone {  
            font-size: 14px;
            line-height: 17px;            
        }
    }

    &__img-block {
        @include m-tablet { 
            flex-shrink: 0; 
            width: 320px;

            img {
                width: 100%;
                height: auto;
            }
        }

        @include m-phone { 
            margin-bottom: 48px;
        }
    }
}