@import "main-bx";

.modal {
    width: 100%;
    max-width: 440px;
    width: 100%;
    padding: 19px 39px 31px 31px !important;
    border-radius: 20px;  

    @include m-phone () {
        padding: 18px 18px 30px 14px !important;
    }

    &_wide {
        max-width: 640px;
        padding: 70px 140px 94px 140px !important;  

        @include m-phone () {
            padding: 50px 16px 40px 16px !important;
        }
    }
    
    &__title {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        color: #160637;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #2C2C2E;
    }

    &__header {
        margin-bottom: 48px;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #160637;

        @include m-phone () {
            margin-bottom: 32px;
            font-size: 30px;
        }
    }

    &__input-block {
        margin-bottom: 16px;
    }

    &__checkbox-block {
        margin-bottom: 48px;
    }
}

.carousel__button.is-close {
    top: 15px !important;
    right: 17px !important;
    color: #722ED1 !important; 

    &:focus {
        box-shadow: none !important;
    }
}

.fancybox__backdrop {
    background: rgba(#722ED1, 0.8) !important;
}

.has-ajax .fancybox__content {
    padding: 0 !important;
}