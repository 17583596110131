@import "main-bx";

.cases {
    padding-top: 70px;
    padding-bottom: 53px;

    @include m-phone {  
        padding-top: 29px;
        padding-bottom: 4px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        display: flex;
        margin-bottom: 63px;
        align-items: center;
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #722ED1;
        background-color: #F7F1FF;
        border-radius: 20px;

        @include m-phone {  
            margin-bottom: 32px;
        }

        svg {
            margin-right: 12px;
            margin-top: -2px;
        }
    }

    &__list {
        display: flex;
        margin-left: -0.5%;
        margin-right: -0.5%;

        @include m-phone {  
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 32.33%;
        margin-left: 0.5%;
        margin-right: 0.5%;

        @include m-phone {  
            width: 100%;
            margin-bottom: 24px;
        }

        svg {
            margin-bottom: 16px;

            @include m-phone {  
                margin-bottom: 8px;
            }
        }
    }

    &__caption {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #160637;

        @include m-phone {  
            margin-bottom: 8px;
        }
    }

    &__text {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #8989A2;
    }
}