@import "main-bx";

.quality {
    position: relative;
    padding-top: 128px;
    padding-bottom: 75px;
  
    @include m-phone {  
        padding-top: 6px;
        padding-bottom: 22px;
    }

    &__container {
        display: flex;

        @include m-phone { 
            flex-direction: column;
            align-items: center;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 103px;
        padding-top: 50px;

        @include m-tablet { 
            padding-left: 30px; 
            padding-top: 20px;
        }

        @include m-phone { 
            padding-left: 0; 
        }
    }

    &__title {
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #722ED1;
        background-color: #F9F0FF;
        border-radius: 20px;

        @include m-phone {  
            margin-bottom: 16px;
        }

        svg {
            margin-right: 12px;
            margin-top: -2px;
        }
    }

    &__caption {
        margin-bottom: 24px;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #160637;

        @include m-phone {  
            margin-bottom: 16px;
            font-size: 26px;
        }
    }

    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #8989A2;
    }

    &__img-block {
        @include m-tablet { 
            flex-shrink: 0; 
            width: 320px;

            img {
                width: 100%;
                height: auto;
            }
        }

        @include m-phone { 
            margin-bottom: 6px;
        }
    }
}