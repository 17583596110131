@import "main-bx";

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    margin-top: 0 !important;
    padding: 0;
}

body {
    font-family: $font_base;
    color: $color_black;
    width: 100%;
    font-size: 22px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 990px; 
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    
    @include m-tablet() {
        max-width: 768px;
        padding: 0 20px;
    }
    @include m-phone() {
        max-width: none;
        padding: 0 16px;
    }
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

.fixed {
    position: fixed;
}

:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

* {
    box-sizing: border-box;
}